<template>
  <v-main tag="div" class="call-content mx-16">
    <v-row>
      <v-col><img src="@/assets/call.png" alt="Call logo" /></v-col>
    </v-row>

    <v-row class="mt-12">
      <v-col cols="4" offset="4">
        <sharedForm :fields="fields" :btn="btn" :options="items" @formOK="$emit('formOk', fields)"></sharedForm>
      </v-col>
    </v-row>

    <div id="snack">
      <v-snackbar v-for="error in errors" :key="error" v-model="snackbar" :timeout="timeout" right rounded="pill"
        color="red darken-2" elevation="24">
        {{ error }}
      </v-snackbar>
    </div>
  </v-main>
</template>

<script>
import sharedForm from "@/components/shared/form";

export default {
  data() {
    return {
      snackbar: false,
      timeout: 3000,
      errors: [],

      fields: [
        {
          type: "phone",
          label: "Appel entrant",
          value: this.datas.phone ? this.datas.phone : "",
          rules: "required",
          name: "appel_entrant",
        },
        {
          type: "select",
          label: "Marchés",
          value: this.datas.marche ? this.datas.marche : "",
          rules: "",
          name: "marche",
          options: [],
        },

      ],

      btn: [{ label: "Rechercher", method: "connect", type: "btn" }],
    };
  },
  components: {
    sharedForm,
  },

  props: {
    items: {
      type: Array,
      default: () => [],
    },
    datas: {
      type: Object,
      default: () => { },
    }
  },
  methods: {
    sendSearch() {
      console.debug("aaahz");
      this.$emit("search");
    },
    valid() {
      console.log(this.fields, "fields after validation");
    },
  },

  created() {
    // console.log(this.items, "items marche");
    // this.fields[1].options = this.items;
    // console.log(this.fields, "fieeee");
    // api.getAllMarches().then((res) => {
    //   this.items = res.data.marches;
    //   console.log(this.items)
    //   this.items.forEach((element) => {
    //     this.items.push(element.marche);
    //   });
    // });
  },
};
</script>

<style scoped>
/* .call-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f8fc;
} */

/* .call-content .cercle-icon {
  width: 100px;
  height: 100px;
  border: 1px solid #9ab9c4;
  border-radius: 99%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-block: 20px;
} */

.call-content .cercle-icon2 {
  width: 120px;
  height: 120px;
  border: 1px solid #e5d7cd;
  border-radius: 99%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-block: 20px;
}

.call-content .cercle-icon3 {
  width: 140px;
  height: 140px;
  border: 1px solid #abaeb3;
  border-radius: 99%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-block: 20px;
}

.call-content .cercle-icon4 {
  width: 160px;
  height: 160px;
  border: 1px solid #e3d1db;
  border-radius: 99%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-block: 20px;
}

.call-content .cercle-icon5 {
  width: 180px;
  height: 180px;
  border: 1px solid #e8ebe6;
  border-radius: 99%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 30px;
  margin-block: 20px;
}

.call-content .cercle-icon:hover {
  background-color: #f1f1f1;
  transition: 0.5s;
}

.call-content h1 {
  font-size: 40px;
  text-transform: uppercase;
  color: #93b121;
  font-weight: lighter;
}

.call-content h2 {
  color: #af2c70;
}

.call-content .search-container {
  display: flex;
  justify-content: center;
}

.call-content .search-btn {
  background-color: #93b121;
  color: #fff;
  padding: 10px 30px;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 10px;
}
</style>
