import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../Store'

Vue.use(VueRouter)


const routes = [
  // login
  {
    path: '/',
    name: 'login',
    meta: {
      restriction: [],
      layout: "no",
      title: 'Login',
    },
    component: () => import(/* webpackChunkName: "home" */'@/pages/login/login.vue'),

  },
  // Forget password
  {
    path: '/forget-password',
    name: 'forgetPassword',
    meta: {
      restriction: [],
      layout: "no",
      title: "Mot de passe oublié"
    },
    component: () => import('@/pages/forgetPassword/forgetPass.vue'),
  },
  //Confirm password
  {
    path: '/reinitpwd/token/:token/id/:id/email/:email',
    name: 'confirmPassword',
    meta: {
      restriction: [],
      layout: "no",
      title: "Nouveau mot de passe"
    },
    component: () => import('@/pages/forgetPassword/confirmPasswordPage'),
  },
  // Call incoming
  {
    path: '/call',
    name: 'call',
    meta: {
      restriction: ["administrateur", "operateur"],
      layout: "no",
      title: 'Appel',
      Auth: true,
    },
    component: () => import('@/pages/call/call.vue')
  },
  // Nouveau client
  {
    path: '/client/new',
    name: 'createClient',
    meta: {
      restriction: ["administrateur", "operateur"],
      layout: 'operator',
      title: "Nouveau client"
    },
    component: () => import('@/pages/clients/clients.vue'),
  },
  // update client
  {
    path: '/client/update/:id_client',
    name: 'updateClient',
    meta: {
      restriction: ["administrateur", "operateur"],
      layout: 'operator',
      title: "Update client"
    },
    component: () => import('@/pages/clients/clients.vue'),
  },
  //Client Informations
  {
    path: '/client/:id_client',
    name: 'client',
    meta: {
      restriction: ["administrateur", "operateur"],
      layout: 'operator',
      Auth: true,
      title: "Fiche client"
    },
    component: () => import('@/pages/clients/clients.vue'),
  },
  //Client Informations Olivier
  // {
  //   path: '/test/client/:id',
  //   name: 'client',
  //   meta: {
  //     restriction: [],
  //     layout: 'operator',
  //     Auth: true,
  //     title: "Fiche client"
  //   },

  //   component: () => import('@/pages/dashboard/clientIndex/clientIndex.vue'),

  // },
  //Client demande pour une nouvelle collecte
  {
    path: '/client/:id_client/new-collecte',
    name: 'newCollecte',
    meta: {
      restriction: ["administrateur", "operateur"],
      layout: 'operator',
      title: "Nouvelle Collecte"
    },
    component: () => import('@/pages/collecte/newCollecte.vue'),
  },
  // modiier la collecte
  {
    path: '/client/:id_client/collecte/:id_collecte',
    name: 'updateCollecte',
    meta: {
      restriction: ["administrateur", "operateur"],
      layout: 'operator',
      title: "Modifier Collecte"
    },
    component: () => import('@/pages/collecte/newCollecte.vue'),
  },

  //Planning collectes
  {
    path: '/client/:id_client/new-collecte/planning',
    name: 'planning',
    meta: {
      restriction: [],
      layout: 'operator',
      title: "Sélectionner la date de collecte"
    },
    component: () => import('@/pages/planningCollecte/planningCollecte.vue'),
  },

  //Modifier collectes planning
  {
    path: '/client/:id_client/update-collecte/:id_collecte/planning',
    name: 'update_planning',
    meta: {
      restriction: [],
      layout: 'operator',
      title: "Sélectionner la date de collecte"
    },
    component: () => import('@/pages/planningCollecte/planningCollecte.vue')
  },
  // all collectes
  {
    path: '/client/:id_client/collectes',
    name: 'collectes',
    meta: {
      restriction: [],
      layout: 'operator',
      title: "Les collectes du client"
    },
    component: () => import('@/pages/clients/allCollectes.vue')
  },

  // --------------------- Côté Admin --------------------

  //Dashboard
  {
    path: '/dashboard',
    name: 'dashboard',
    props: true,
    meta: {
      restriction: ["administrateur"],
      Auth: true,
      layout: 'admin',
      title: "Dashboard"
    },
    component: () => import('@/pages/admin/dashboard/dashboard.vue'),
  },

  //Clients
  {
    path: '/clients',
    name: 'clients',
    meta: {
      restriction: ["administrateur"],
      Auth: true,
      layout: 'admin',
      title: "Clients"
    },
    component: () => import('@/pages/admin/clients/clients.vue'),
  },

  //Export page
  {
    path: '/export',
    name: 'export',
    meta: {
      restriction: ["administrateur", "CAESE"],
      Auth: true,
      layout: 'admin',
      title: 'Export',
    },
    component: () => import('@/pages/admin/export/export.vue'),
  },
  //Export page CCVE
  {
    path: '/export/ccve',
    name: 'export_ccve',
    meta: {
      restriction: ["administrateur", "CCVE"],
      Auth: true,
      layout: 'admin',
      title: 'Export',
    },
    component: () => import('@/pages/admin/export/export_ccve.vue'),
  },
  //Export page CAESE
  {
    path: '/export/caese',
    name: 'export_caese',
    meta: {
      restriction: ["administrateur", "CAESE"],
      Auth: true,
      layout: 'admin',
      title: 'Export',
    },
    component: () => import('@/pages/admin/export/export_caese.vue'),
  },
  //planning page
  {
    path: '/planning',
    name: 'planning_admin',
    meta: {
      restriction: ["administrateur"],
      Auth: true,
      layout: 'admin',
      title: 'Planning',
    },
    component: () => import('@/pages/admin/planning/planning.vue'),
  },
  //Administration
  {
    path: '/administration',
    name: 'administration',
    meta: {
      restriction: ["administrateur"],
      Auth: true,
      layout: 'admin',
      title: "Administration"
    },
    component: () => import('@/pages/admin/administration/administration.vue'),
  },

  // route à laisser à la fin pour gérer les routes n'existant pas
  {
    path: '*',
    redirect: '/404'
  },
]




const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


// this function looks for an item in the route object to determine that a route is auth protected
// meta: { Auth: true }
router.beforeEach((to, from, next) => {

  // PERSONNALISER LE TITLE AVEC CELUI DONNÉ DANS routes.js meta.title

  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  if (to.matched.some(record => record.meta.Auth)) {
    if (!localStorage.getItem("user-token")) {
      next({
        name: 'login',
      })
    } else {
      // check sur les restriction == roles - si ok next() - sinon next(false)
      store.dispatch("isAuthenticated").then(() => {
        if (to.meta.restriction.includes(store.state.user.Role.role) || to.meta.restriction.length == 0) {
          // console.log("user autorisé", store.state.user)
          next()
        } else {
          // console.log("user non autorisé", store.state.user)
          next({
            name: 'login',
          })
        }
      });
    }
  } else if (localStorage.getItem("user-token")) {
    // Sur la connexion
    store.dispatch("isAuthenticated");
    next()
  }

  else {
    next()
  }
})




export default router
