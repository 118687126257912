<template>
  <v-card id="contact">
    <validation-observer ref="form">
      <v-row no-gutters>
        <v-col cols="12">
          <h2 v-if="client.id_type_client == 1" :class="[$route.name == 'createClient' ? 'create' : 'update']">
            INFORMATIONS DE CONTACT
          </h2>
          <h2 style="color: #0084c5" v-else :class="[$route.name == 'createClient' ? 'create' : 'update']">
            INFORMATIONS DE CONTACT
          </h2>
          <div v-if="client.id_type_client == 1" class="line title"
            :class="[$route.name == 'createClient' ? 'create' : 'update']"></div>
          <div v-else class="blue-line title" :class="[$route.name == 'createClient' ? 'create' : 'update']"></div>
          <v-row no-gutters class="tal">
            <v-col cols="12">
              <v-row no-gutters class="mt-4">
                <v-col xl="4" lg="4" md="4" cols="3" class="titles">
                  <v-row no-gutters class="th" align="center">
                    <p class="">MARCH&Eacute; *</p>
                  </v-row>
                  <v-row no-gutters class="th" align="center">
                    <p class="">NUM&Eacute;RO D'ADH&Eacute;RENT <span v-if="client.id_marche == 1">*</span></p>
                  </v-row>
                  <v-row no-gutters class="th" align="center">
                    <p class="">TYPE *</p>
                  </v-row>
                </v-col>
                <v-col xl="8" lg="8" md="8" sm="9" cols="9" class="value">
                  <v-row no-gutters class="th" align="center">
                    <v-col>
                      <ValidationProvider name="marché" rules="required">
                        <v-select :items="allMarches" v-model="client.id_marche" item-text="marche"
                          item-value="id_marche" dense outlined hide-details
                          @change="getCommunesByMarche(client.id_marche)"></v-select>
                      </ValidationProvider>
                    </v-col></v-row>
                  <v-row no-gutters class="th" align="center"><v-col>
                      <ValidationProvider name="numéro d'adhérent" :rules="{
                        required: client.id_marche == 1
                      }">
                        <v-text-field v-model="client.numero_adherent" outlined dense hide-details></v-text-field>
                      </ValidationProvider>
                    </v-col></v-row>
                  <v-row no-gutters class="th" align="center">
                    <v-col>
                      <ValidationProvider name="type de client" rules="required">
                        <v-select :items="allTypes" v-model="client.id_type_client" item-text="typologie"
                          item-value="id_type_client" dense outlined hide-details @change="
                            client.id_type_client == 1
                              ? (isBailleur = false)
                              : (isBailleur = true)
                            "></v-select>
                      </ValidationProvider>
                    </v-col></v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <div class="line w100 mt10" :class="[$route.name == 'createClient' ? 'create' : 'update']"></div>
          </v-row>
          <v-row no-gutters class="tal ">
            <v-col xl="4" lg="4" md="4" cols="3" class="titles">
              <div v-if="client.id_type_client == 1">
                <v-row no-gutters class="h">
                  <p class="">CIVILITE *</p>
                </v-row>
                <v-row no-gutters class="th" align="center">
                  <p class="">NOM *</p>
                </v-row>
                <v-row no-gutters class="th" align="center">
                  <p class="">PR&Eacute;NOM *</p>
                </v-row>
              </div>
              <div v-else>
                <v-row no-gutters class="th" align="center">
                  <p class="">BAILLEUR *</p>
                </v-row>
                <v-row no-gutters class="th" align="center">
                  <p class="">CONTACT PRIVILÉGIÉ *</p>
                </v-row>
              </div>
              <v-row no-gutters class="h">
                <p class="">DATE DE NAISSANCE</p>
              </v-row>

              <v-row no-gutters class="h">
                <p class="mt-4">PMR</p>
              </v-row>
            </v-col>
            <v-col xl="8" lg="8" md="8" cols="9" class="value">
              <div v-if="client.id_type_client == 1">
                <v-row no-gutters class="h"><v-col>
                    <ValidationProvider name="civilite" rules="required">
                      <v-radio-group v-model="client.civilite" row>
                        <v-radio class="fontLabel" label="Monsieur" value="monsieur"></v-radio>
                        <v-radio class="fontLabel" label="Madame" value="madame"></v-radio>
                      </v-radio-group>
                    </ValidationProvider>
                  </v-col></v-row>
                <v-row no-gutters class="th" align="center">
                  <v-col>
                    <ValidationProvider name="nom" rules="required">
                      <v-text-field v-model="client.nom" outlined dense hide-details></v-text-field>
                    </ValidationProvider>
                  </v-col></v-row>
                <v-row no-gutters class="th" align="center">
                  <v-col>
                    <ValidationProvider name="prénom" rules="required">
                      <v-text-field v-model="client.prenom" outlined dense hide-details></v-text-field>
                    </ValidationProvider>
                  </v-col></v-row>
              </div>
              <div v-else>
                <v-row no-gutters class="th" align="center">
                  <v-col>
                    <ValidationProvider name="bailleur" rules="required">
                      <v-text-field v-model="client.bailleur" outlined dense hide-details></v-text-field>
                    </ValidationProvider>
                  </v-col></v-row>

                <v-row no-gutters class="th" align="center">
                  <v-col>
                    <ValidationProvider name="contact privilégié" :rules="{
                      required: isBailleur,
                    }">
                      <v-text-field v-model="client.contact_privilegie" outlined dense hide-details></v-text-field>
                    </ValidationProvider>
                  </v-col></v-row>
              </div>
              <v-row no-gutters class="h" align="center">
                <v-col>

                  <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                    :return-value.sync="client.date_naissance" transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                      <ValidationProvider name="date de naissance" :rules="{
                        regex: /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/,
                      }">
                        <v-text-field :value="client.date_naissance" v-model="date_naissance" v-bind="attrs" outlined
                          dense hide-details>

                          <template v-slot:append>
                            <img
                              @click="$moment(client.date_naissance, 'DD/MM/YYYY').format('YYYY-MM-DD') == 'Invalid date' ? client.date_naissance = $moment().format('YYYY-MM-DD') : $moment(date_naissance, 'DD/MM/YYYY').format('YYYY-MM-DD')"
                              v-on="on" class="img-calendar" src="@/assets/images/administration/calendrier.png"
                              alt="Calendar">
                          </template>
                        </v-text-field>
                      </ValidationProvider>
                    </template>
                    <v-date-picker v-model="client.date_naissance" no-title locale="fr-fr" @change="
                      $refs.menu.save(client.date_naissance);
                    date_naissance = $moment(client.date_naissance).format('DD/MM/YYYY')
                    menu = false;
                    ">
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <!-- {{client.date_naissance}} -->
              <!-- <v-row v-if="client.date_naissance == 'Invalid date'">{{client.date_naissance}} La date doit être au format jj/mm/aaaa ex: 10/06/1900 {{$refs.form.errors}} </v-row> -->
              <v-row no gutters class="h ml-1" align="center">
                <v-radio-group mandatory v-model="client.carte_handicap" row>
                  <v-radio color="indigo darken-3" :value="true" label="Oui"></v-radio>
                  <v-radio color="indigo darken-3" :value="false" label="Non"></v-radio>
                </v-radio-group>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <div class="line w100 mt10" :class="[$route.name == 'createClient' ? 'create' : 'update']"></div>
      </v-row>

      <v-row no-gutters class="tal">
        <v-col cols="12">
          <v-row no-gutters>
            <v-col xl="4" lg="4" md="4" cols="3" class="titles">
              <v-row no-gutters class="th" align="center">
                <p class="">ADRESSE *</p>
              </v-row>
              <v-row no-gutters class="th" align="center">
                <p class="">COMPL&Eacute;MENT D'ADRESSE</p>
              </v-row>
              <v-row no-gutters class="th" align="center">
                <p class="">COMMUNE *</p>
              </v-row>
            </v-col>
            <v-col xl="8" lg="8" md="8" cols="9" class="value">
              <v-row no-gutters class="th" align="center">
                <v-col>
                  <ValidationProvider name="adresse" rules="required">
                    <v-text-field v-model="client.adresse" outlined dense hide-details></v-text-field>
                  </ValidationProvider>
                </v-col></v-row>
              <v-row no-gutters class="th" align="center"><v-col>
                  <ValidationProvider>
                    <v-text-field v-model="client.complement_adresse" outlined dense hide-details>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row no-gutters class="th" align="center">
                <v-col>
                  <ValidationProvider name="commune" rules="required">
                    <v-autocomplete outlined :items="communes" item-text="all" item-value="id_commune"
                      v-model="client.id_commune" hide-details no-data-text="Sélectionnez un marché"
                      dense></v-autocomplete>
                  </ValidationProvider>
                </v-col></v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <div class="line w100 mt10" :class="[$route.name == 'createClient' ? 'create' : 'update']"></div>
      </v-row>

      <v-row no-gutters class="tal">
        <v-col cols="12">
          <v-row no-gutters>
            <v-col xl="4" lg="4" md="4" cols="3" class="titles">
              <v-row no-gutters class="th" align="center">
                <p class="">INFORMATIONS UTILES</p>
              </v-row>
              <v-row no-gutters class="th" align="center">
                <p class="">T&Eacute;L&Eacute;PHONE FIXE *</p>
              </v-row>
              <v-row no-gutters class="th" align="center">
                <p class="">T&Eacute;L&Eacute;PHONE MOBILE *</p>
              </v-row>
              <v-row no-gutters class="th" align="center">
                <p class="">EMAIL <span v-if="client.id_marche == 1">*</span></p>
              </v-row>
            </v-col>
            <v-col xl="8" lg="8" md="8" cols="9" class="value">
              <v-row no-gutters class="th" align="center">
                <v-col>
                  <v-text-field v-model="client.informations" outlined dense hide-details></v-text-field>
                </v-col></v-row>
              <v-row no-gutters class="th" align="center"><v-col>
                  <ValidationProvider name="téléphone fixe" :rules="{
                    required:
                      !client.telephone_mobile ||
                      client.telephone_mobile.length === 0,
                    regex: /^(0|\+33)( *[1-9])( *[0-9]{2}){4}$/,
                  }">
                    <v-text-field v-model="client.telephone_fixe" outlined dense hide-details></v-text-field>
                  </ValidationProvider>
                </v-col></v-row>
              <v-row no-gutters class="th" align="center"><v-col>
                  <ValidationProvider name="téléphone mobile" :rules="{
                    required:
                      !client.telephone_fixe ||
                      client.telephone_fixe.length === 0,
                    regex: /^(0|\+33)( *[1-9])( *[0-9]{2}){4}$/,
                  }">
                    <v-text-field v-model="client.telephone_mobile" outlined dense hide-details></v-text-field>
                  </ValidationProvider>
                </v-col></v-row>
              <v-row no-gutters class="th" align="center"><v-col>
                  <ValidationProvider name="email" :rules="{
                    required: client.id_marche == 1,
                    email
                  }">
                    <v-text-field v-model="client.email" outlined dense hide-details></v-text-field>
                  </ValidationProvider>
                </v-col></v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="mt-12 mb-3" no-gutters>
        <v-col>
          <v-row justify="space-around">
            <v-btn class="font_1 mt-5" :class="[$route.name == 'createClient' ? 'create' : 'update']" x-large
              elevation="1" style="
                background-color: #93b122;
                color: white;
                border-radius: 15px;
              " width="40%" @click="validate()">
              <span v-if="$route.name == 'createClient'">CREER LA FICHE CONTACT</span>
              <span style="font-size: 14px" v-if="$route.name == 'updateClient'">MODIFIER</span>
            </v-btn>

            <v-btn style="font-size: 14px; border-radius: 15px" v-if="$route.name == 'updateClient'" class="font_1 mt-5"
              x-large elevation="1" color="#D61111" dark width="40%" @click="cancel">ANNULER</v-btn>
          </v-row>
        </v-col>
      </v-row>
    </validation-observer>
  </v-card>
</template>
<script>
import apiClient from "@/api/clients";
import apiCommunes from "@/api/communes"

export default {
  props: {
    client: {
      type: Object,
      default: () => { },
    },
    allTypes: {
      type: Array,
      default: () => [],
    },
    allMarches: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      snackbar: false,
      timeout: 3000,
      errors: [],
      date_naissance: '',
      email: "",

      menu: false, // affichier datepicker

      switchType: "",
      isBailleur: false,
      communes: [],
      isCCVE: false,
      activePicker: null
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.client.date_naissance
        ? this.$moment(this.client.date_naissance).format("DD/MM/YYYY")
        : "";
    },

    // computedBailleurContact() {
    //   return (
    //     this.client.civilite + " " + this.client.nom + " " + this.client.prenom
    //   );
    // },
  },
  methods: {
    cancel() {
      this.$router.push({ name: "client" });
    },

    blackList(value) {
      this.$emit("blackList", value);
      this.showPopup = false;
    },
    errorFields(value) {
      console.log("emit dans commentaire");
      this.$emit("errorFields", value);
    },
    validate: function () {
      console.log(this.client.date_naissance);
      this.$refs.form.validate().then((valid) => {
        console.log(this.$refs.form.errors);
        if (valid) {
          // this.client.date_naissance = this.$moment(this.client.date_naissance, 'DD/MM/YYYY').format('YYYY-MM-DD')
          console.log(valid, "form ok", this.client);
          if (this.$route.name == "createClient") {
            apiClient
              .nouveauClient(this.client)
              .then((res) => {
                console.log(
                  "on est dans la repo,nse",
                  res.data.client.id_client
                );
                // this.$router.push("/client/" + res.data.client.id_client);
                this.$router.push({
                  name: "client",
                  params: { id_client: res.data.client.id_client },
                });
              })
              .catch((err) => {
                console.log(err, "error api");
              });
          } else if (this.$route.name == "updateClient") {
            console.log(this.$route, "routtttt");
            // this.client["id_type_client"] = this.client.id_type_client;
            delete this.client.Collectes;
            delete this.client.Commentaires;
            delete this.client.nextCollect;
            delete this.client.TypeClient;
            delete this.client.historique_collectes;
            delete this.client.Marche;
            delete this.client.updatedAt;
            delete this.client.createdAt;

            apiClient
              .updateClient(this.client)
              .then((res) => {
                this.errors = ["Les modifications ont bien été enregistrées"];
                console.log(
                  "on est dans la repo,nse",
                  res.data.client.id_client
                );
                this.$emit("errorFields", {
                  errors: this.errors,
                  color: "#93b122",
                });

                // this.$router.push("/client/" + res.data.client.id_client);
                setTimeout(() => {
                  this.$router.push({
                    name: "client",
                    params: { id_client: this.client.id_client },
                  });
                }, this.timeout);
              })
              .catch((err) => {
                console.log(err, "error api");
              });
          }
        } else {
          //display error
          console.log(valid, "form KO");
          this.errors = [];
          let e = this.$refs.form.errors;
          for (const field in e) {
            e[field].forEach((msg) => {
              msg = msg.includes("invalide") && !(msg.includes("date de naissance"))
                ? msg.split("est")[0] +
                "doit commencé par +33 ou 0 et comporté 9 autres chiffres"
                : msg;
              this.errors.push(msg);
            });

            this.$emit("errorFields", {
              errors: this.errors,
              color: "red darken-2",
            });
          }
        }
      });
    },

    getCommunesByMarche(id_marche) {
      this.isCCVE = false;
      if (id_marche == 1) {
        this.isCCVE = true;
      }
      apiCommunes.getCommunes(id_marche).then((res) => {
        console.log("les communes ", res.data.communes)
        this.communes = res.data.communes
      })
    },
  },

  watch: {
    client(nv, ov) {
      console.log(ov.date_naissance, nv.date_naissance, "watch date_naissance")
      console.log(this.client, "client watch");
      this.$route.name == "createClient" ? false : this.date_naissance = this.client.date_naissance != null ? this.$moment(this.client.date_naissance).format('DD/MM/YYYY') : '';


      console.log("id_commune", nv.id_commune, this.date_naissance)
      this.getCommunesByMarche(nv.Marche.id_marche)
      this.client.id_commune = nv.id_commune

    },

    // 'client.date_naissance'(nv, ov) {
    //     if (ov == "Invalid date") {
    //       this.client.date_naissance
    //     }
    //   },
    date_naissance(nv, ov) {
      console.log(nv, ov, "watch date");
      if (nv != ov && nv.length > 9 || /[a-z]/i.test(nv)) {
        this.client.date_naissance = this.$moment(nv, 'DD/MM/YYYY').format('YYYY-MM-DD')
      }
    },
  },
  async created() {
    if (this.$route.query.phone != undefined) {
      if (this.$route.query.phone.charAt(3) == 6 || this.$route.query.phone.charAt(3) == 7) {
        this.client.telephone_mobile = this.$route.query.phone;
      } else {
        this.client.telephone_fixe = this.$route.query.phone;
      }


      // this.marche = this.$route.query.tag;
    }
    if (this.$route.query.marche != undefined) {
      setTimeout(function () {
        console.log(this.allMarches, this.$route.query.marche, 'marche non vide')
        let marche = this.allMarches.filter(marche => marche.tag == this.$route.query.marche);
        this.client.id_marche = marche[0].id_marche;
        console.log(this.client.id_marche, 'mmmmmmmmmmmmmmmmmmmarcge');

      }.bind(this), 300)


    }
  },

  mounted() {

    // this.getCommunesByMarche(this.$store.state.client.id_marche);
    this.client.carte_handicap =
      this.$route.name == "createClient" ? false : this.client.carte_handicap;
    console.log("Bailleur: ", this.bailleurContact);
  },
};
</script>
<style scoped>
@import "./create.css";
</style>
