<template>
  <v-container id="pageCall" fluid class="call-container">
    <div id="snack">
      <v-snackbar v-for="error in err" :key="error" v-model="snackbar" :timeout="timeout" right rounded="pill"
        color="red darken-2" elevation="24">
        {{ error }}
      </v-snackbar>
    </div>
    <v-row>
      <v-col cols="6" offset="3">
        <call @search="call" :items="items" @formOk="call($event)" :datas="{ marche: marche, phone: phone }"></call>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Call from "@/components/shared/call";
import api from "@/api/clients";
import apiMarche from "@/api/marches";

export default {
  data() {
    return {
      timeout: 3000,
      err: [],
      snackbar: false,
      items: [],
      phone: '',
      marche: ''
    };
  },
  components: {
    Call,
  },

  methods: {
    call: function (fields) {
      const data = {};
      fields.forEach((field) => {
        data[field.name] = field.value;
      });

      api
        .call(data)
        .then((res) => {
          this.$router.push({
            name: "client",
            params: { id_client: res.data.client.id_client },
          });
        })
        .catch((error) => {
          console.error('error', error);
          if (
            500 === error.response.status
          ) {
            this.snackbar = true;
            // erreur depuis le back

            this.err.splice(0, 1);
            this.err.push(error.response.data.client);
          } else if (400 === error.response.status) {
            // pas de client
            this.$router.push({ name: "createClient", query: { marche: data.marche, phone: data.appel_entrant } });
          }
        });
    },
  },
  created() {
    apiMarche.getAllMarches().then((res) => {
      this.items = res.data.marches;
      console.log(this.items);
    });

    if (this.$route.query.phone != undefined) {
      this.phone = this.$route.query.phone;
      this.marche = this.$route.query.tag;

      api
        .call({ appel_entrant: this.phone, marche: this.marche })
        .then((res) => {
          this.$router.push({
            name: "client",
            params: { id_client: res.data.client.id_client },
          });
        })
        .catch((error) => {

          if (
            // 401 === error.response.status ||
            // 400 === error.response.status ||
            500 === error.response.status
          ) {
            this.snackbar = true;
            // erreur depuis le back

            this.err.splice(0, 1);
            this.err.push(error.response.data.client);
          } else if (400 === error.response.status) {
            // pas de client
            console.log(this.marche, this.phone);
            this.$router.push({ name: "createClient", query: { marche: this.marche, phone: this.phone } });
          } else if (401 == error.response.status) {
            this.$router.push({ name: "login" });
          }
        });
    }
  },
};
</script>

<style scoped>
.call-container#pageCall {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
  padding: 0;
}

h1 {
  margin-bottom: 10vh;
}
</style>
