import axios from "axios";

const RESSOURCE_NAME = "marches";

export default {
  getAllMarches() {
    return axios.get(`${RESSOURCE_NAME}`);
  },
  getAllCreneaux(id_marche, id_index, code_postal, ville, type_client) {
    return axios.post(`creneaux/getcreneaux/${id_marche}/${type_client}/${id_index}`, {
      code_postal: code_postal,
      ville: ville,
    });
  },
  getSecteurByMarche(id_marche) {
    return axios.get(`${RESSOURCE_NAME}/secteurs/${id_marche}`);
  },
  getAllSecteurs(id_marche) {
    return axios.get(`${RESSOURCE_NAME}/secteurs/${id_marche}`);
  },

  getTarifs(id_marche) {
    return axios.get(`${RESSOURCE_NAME}/tarifs/${id_marche}`);
  },
  exportTournee(data) {
    return axios.post(
      `${RESSOURCE_NAME}/export_tournee`,
      {
        id_marche: data.id_marche,
        id_secteur: data.id_secteur,
        type: data.type,
        date_debut: data.date_debut,
        date_fin: data.date_fin,
        heure_debut: data.heure_debut,
        heure_fin: data.heure_fin,
      },
      { responseType: "blob" }
    );
  },
  exportTourneeCAESE(data) {
    return axios.post(
      `${RESSOURCE_NAME}/export_tournee`,
      {
        id_marche: data.id_marche,
        id_secteur: data.id_secteur,
        type: data.type,
        date_debut: data.date_debut,
        date_fin: data.date_fin,
        heure_debut: data.heure_debut,
        heure_fin: data.heure_fin,
      },
      { responseType: "blob" }
    );
  },
  bonEnlevementPdf(data) {
    return axios.post(
      `${RESSOURCE_NAME}/bon_enlevement_pdf`,
      {
        id_marche: data.id_marche,
        id_secteur: data.id_secteur,
        date_debut: data.date_debut,
        date_fin: data.date_fin,
        heure_debut: data.heure_debut,
        heure_fin: data.heure_fin,
      }
      // ,
      // { responseType: "blob" }
    );
  },
  downloadBonEnlevement(data) {
    return axios.post(`${RESSOURCE_NAME}/download/bon`, { filePath: data.filePath, filename: data.filename }, { responseType: 'blob' })
  },
  exportFacturationDetaillee(data) {
    return axios.post(
      `${RESSOURCE_NAME}/export_facturation`,
      {
        id_marche: data.id_marche,
        id_secteur: data.id_secteur,
        type: data.type,
        date_debut: data.date_debut,
        date_fin: data.date_fin,
        heure_debut: data.heure_debut,
        heure_fin: data.heure_fin,
      },
      { responseType: "blob" }
    );
  },

  exportFacturationMensuelle(data) {
    return axios.post(
      `${RESSOURCE_NAME}/export_facturation_mensuelle`,
      {
        id_marche: data.id_marche,
        id_secteur: data.id_secteur,
        date_debut: data.date_debut,
        date_fin: data.date_fin,
      },
      { responseType: "blob" }
    );
  },
  createMarche(data) {
    return axios.post(`${RESSOURCE_NAME}/create`, {
      marche: data.marche,
      prix_collecte_particulier: data.prix_collecte_particulier,
      prix_collecte_collectif: data.prix_collecte_collectif
    });
  },
  addTarif(data) {
    return axios.post(`${RESSOURCE_NAME}/tarif`, data);
  },
};
